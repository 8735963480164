import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Dropdown } from "@components/fields/dropdown";
import { Carousel } from "@components/carousel";

import { Eyebrow } from "@pageComponents/destinations/eyebrow";
import { Heading } from "@pageComponents/destinations/heading";

import {
  CutleryFeature,
  CompassFeature,
  BeachesFeature,
  ArtAndCultureFeature,
  CheckmarkFeature,
} from "@icons";

import { sortLatestArticles } from "@utils/articles/sortLatestArticles";
import { keyify } from "@utils/keyify";
import { tagular } from "@cohesion/tagular";

import { withSave } from "@components/_hocs/withSave";
import { PrimaryCard } from "./cards/primary";
import { SecondaryCard } from "./cards/secondary";

export function LatestArticles({
  eyebrow = null,
  title = null,
  enableFilters = true,
  ctx = null,
  articles = [],
  showNewsLink = false,
}) {
  const [awaiting, setAwaiting] = useState(false);
  const [arts, setArts] = useState(articles);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filters, setFilters] = useState(
    enableFilters
      ? [
          /**
           * Note: Adventure travel is not a true tag slug, and is comprised of
           * various other tags sorted in the API route.
           *
           * `All` is also not a true slug and handled uniquely, as filter clearance
           */
          { label: "All Interests", slug: "all", icon: CheckmarkFeature },
          {
            label: "Adventure Travel",
            slug: "adventure-travel",
            icon: CompassFeature,
          },
          {
            label: "Art & Culture",
            slug: "art-and-culture",
            icon: ArtAndCultureFeature,
          },
          {
            label: "Beaches, Coasts & Islands",
            slug: "beaches",
            icon: BeachesFeature,
          },
          {
            label: "Food & Drink",
            slug: "food-and-drink",
            icon: CutleryFeature,
          },
        ]
      : []
  );

  /**
   * requires ctx prop
   */
  const handleFilter = async filter => {
    setAwaiting(true);

    await fetch(`/api/articles/${filter}/${ctx.slug}`)
      .then(res => res.json())
      .then(json => {
        if (!json.articles) return;
        const n = sortLatestArticles(json.articles.items);

        setActiveFilter(filter);
        setArts(n);
      })
      .then(() => setAwaiting(false));

    tagular("click", {
      actionOutcome: "filter",
      outboundUrl: "null",
      webElement: {
        location: "section",
        position: "lateststories",
        text: filter,
        elementType: "tab",
      },
    });
  };

  useEffect(() => {
    const setupFilters = async () =>
      fetch(`/api/articles/count/${ctx.slug}`)
        .then(res => res.json())
        .then(json => {
          let copy = filters;

          Object.keys(json).forEach(key => {
            if (json[key] < 3 && key !== "all") {
              copy = copy.filter(f => f.slug !== key);
            }
          });

          setFilters(copy);
        })
        .catch(() => {
          setFilters([]);
        });
    if (!enableFilters || !ctx) return;
    setupFilters();
  }, [ctx?.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  let storiesTitle;
  if (title) {
    storiesTitle = title;
  } else if (!title && ctx.title) {
    storiesTitle = (
      <>
        Latest stories
        {ctx.title && (
          <>
            {" "}
            <span className="inline-block">from {ctx.title}</span>
          </>
        )}
      </>
    );
  } else {
    storiesTitle = (
      <>
        Latest updates{" "}
        <span className="inline-block">from around the world</span>
      </>
    );
  }

  const FeatureCard = arts?.primary?.esid ? withSave(PrimaryCard) : PrimaryCard;
  const SaveSecondaryCard = withSave(SecondaryCard);
  return (
    <section className="max-w-full mx-4 my-16 articles lg:my-24 md:mx-auto md:container">
      <div>
        {eyebrow && <Eyebrow nav="Articles">{eyebrow}</Eyebrow>}
        <header className="flex items-end justify-between mt-5 mb-10 lg:mt-8 lg:mb-14">
          <Heading className="">{storiesTitle}</Heading>
          <div className="flex-none hidden md:inline-block">
            {showNewsLink && (
              <a
                href={ctx?.slug ? `/${ctx.slug}/news` : "/news"}
                className="mr-4 btn"
                onClick={() => {
                  tagular("click", {
                    actionOutcome: "internallink",
                    outboundUrl: ctx?.slug ? `/${ctx.slug}/news` : "/news",
                    webElement: {
                      location: ctx?.location ? ctx.location : "section",
                      position: "lateststories",
                      text: "more",
                      elementType: "link",
                    },
                  });
                }}
              >
                Read more news
              </a>
            )}
            <a
              href={ctx?.slug ? `/${ctx.slug}/articles` : "/articles"}
              className="btn"
              onClick={() => {
                tagular("click", {
                  actionOutcome: "internallink",
                  outboundUrl: ctx.slug ? `/${ctx.slug}/articles` : "/articles",
                  webElement: {
                    location: ctx?.location ? ctx.location : "section",
                    position: "lateststories",
                    text: "more",
                    elementType: "link",
                  },
                });
              }}
            >
              Read more articles
            </a>
          </div>
        </header>

        {(!ctx?.type ||
          ctx?.type === "Country" ||
          ctx?.type === "Region" ||
          ctx?.type === "Continent") &&
          filters.length > 1 &&
          enableFilters && (
            <div>
              <p htmlFor="article-filters" className="text-sm text-[#444444]">
                Filter by interest:
              </p>

              {/**
               * Mobile
               */}
              <Dropdown
                id="article-filters"
                options={filters}
                onSelect={filter => {
                  handleFilter(filter.slug);
                }}
                onOpen={() =>
                  tagular("click", {
                    actionOutcome: "filter",
                    outboundUrl: "null",
                    webElement: {
                      location: ctx?.location ? ctx.location : "section",
                      position: "lateststories",
                      text: "open",
                      elementType: "dropdown",
                    },
                  })
                }
                className="z-20 pb-8 mt-4 lg:hidden"
              />

              {/**
               * Desktop
               */}
              <ul
                id="article-filters"
                className="hidden gap-6 mt-4 mb-8 lg:flex"
              >
                {filters.map(filter => {
                  const Icon = filter.icon;

                  return (
                    <li
                      key={keyify(filter.label)}
                      className="text-[#444444] font-semibold"
                    >
                      <button
                        type="button"
                        onClick={() => handleFilter(filter.slug)}
                        className={cn(
                          "flex items-center text-sm hover:text-black",
                          { "text-black": filter.slug === activeFilter }
                        )}
                      >
                        <Icon className="mr-2 text-xl" />{" "}
                        <span
                          className={cn({
                            "border-b border-black":
                              filter.slug === activeFilter,
                          })}
                        >
                          {filter.label}
                        </span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
      </div>

      <div className="relative z-0 lg:flex lg:gap-x-8">
        {arts.primary && (
          <div className="mb-8 lg:w-3/5 lg:shrink-0 lg:mb-auto">
            <FeatureCard
              type="article"
              loading={awaiting}
              {...arts.primary}
              hocTagularCtx={{
                name: "LATESTSTORIES",
                position: "LATESTSTORIES",
              }}
              tagularCtx={{
                location: ctx?.location ? ctx.location : "section",
              }}
            />
          </div>
        )}
        {Array.isArray(arts?.additional) && arts?.additional[0] && (
          <Carousel
            contained={false}
            className="w-full carousel-wrapper lg:px-0"
            pageSize={1}
            tagularPosition="lateststories"
            mobileItemSize="full"
          >
            {arts.additional
              .reduce((acc, curr, i) => {
                // Create groups of 4 items
                if (!(i % 4)) {
                  acc.push(arts.additional.slice(i, i + 4));
                }

                return acc;
              }, [])
              .map(group => (
                <div
                  className="flex flex-col gap-y-4 lg:h-full"
                  key={keyify(group.map(art => art.key).join("-"))}
                >
                  {group.map(article =>
                    article?.esid ? (
                      <SaveSecondaryCard
                        key={keyify(article.key)}
                        type="article"
                        loading={awaiting}
                        {...article}
                        hocTagularCtx={{
                          name: "LATESTSTORIES",
                          position: "LATESTSTORIES",
                        }}
                        tagularCtx={{
                          location: ctx?.location ? ctx.location : "section",
                        }}
                      />
                    ) : (
                      <SecondaryCard
                        key={keyify(article.key)}
                        type="article"
                        loading={awaiting}
                        {...article}
                        tagularCtx={{
                          location: ctx?.location ? ctx.location : "section",
                        }}
                      />
                    )
                  )}
                </div>
              ))}
          </Carousel>
        )}
        <div className="flex justify-center space-x-4">
          {showNewsLink && (
            <a
              href={ctx?.slug ? `/${ctx.slug}/news` : "/news"}
              className="block w-full mt-6 text-center lg:hidden btn"
              onClick={() => {
                tagular("click", {
                  actionOutcome: "internallink",
                  outboundUrl: ctx?.slug ? `/${ctx.slug}/news` : "/news",
                  webElement: {
                    location: ctx?.location ? ctx.location : "section",
                    position: "lateststories",
                    text: "more",
                    elementType: "link",
                  },
                });
              }}
            >
              Read more news
            </a>
          )}
          <a
            href={ctx?.slug ? `/${ctx.slug}/articles` : "/articles"}
            className="block w-full mt-6 text-center lg:hidden btn"
            onClick={() => {
              tagular("click", {
                actionOutcome: "internallink",
                outboundUrl: ctx?.slug ? `/${ctx.slug}/articles` : "/articles",
                webElement: {
                  location: ctx?.location ? ctx.location : "section",
                  position: "lateststories",
                  text: "more",
                  elementType: "link",
                },
              });
            }}
          >
            Read more articles
          </a>
        </div>
      </div>
      <style jsx>{`
        .articles :global(.carousel-wrapper) {
          padding-left: 0;
          padding-right: 0;
        }
        .articles :global(.carousel-item):not(:first-child) {
          display: none;
        }
        .articles :global(.carousel + div) {
          display: none;
        }
        @media only screen and (min-width: 1024px) {
          .articles :global(.carousel-item):not(:first-child) {
            display: block;
          }
          .articles :global(.carousel + div) {
            display: flex;
          }
        }
      `}</style>
    </section>
  );
}

LatestArticles.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  enableFilters: PropTypes.bool,
  articles: PropTypes.shape({
    primary: PropTypes.shape({
      key: PropTypes.string.isRequired,
      esid: PropTypes.string,
      excerpt: PropTypes.string.isRequired,
      subhead: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string,
      }),
      href: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({ title: PropTypes.string.isRequired })
      ),
      title: PropTypes.string.isRequired,
    }).isRequired,
    additional: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        esid: PropTypes.string,
        excerpt: PropTypes.string.isRequired,
        subhead: PropTypes.string,
        image: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string.isRequired,
        }),
        href: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(
          PropTypes.shape({ title: PropTypes.string.isRequired })
        ),
        title: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }),
  ctx: PropTypes.shape({
    location: PropTypes.string,
    enableFilters: PropTypes.bool,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    slug: PropTypes.string.isRequired,
    parent: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  }),
  showNewsLink: PropTypes.bool,
};
